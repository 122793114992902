import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import Status from '../core/status';
import Consts from '../core/consts'
import {DEPeriods, GBPeriods, GBPeriods_date} from "./filing_support"
import {DateInput, InsertIfNonClient, Loader, SelectField, SelectfieldInput, TextfieldInput} from "../core/input_fields";
import ViewConfig from "../core/view_config";
import JSONPretty from "react-json-pretty";
import {apiGet, encodeGetParams} from "../core/api";
import {ListOpenItems, ListSubmittedItems, ListUnsubmittedItems} from "./filing_list_components";
import {FormContext} from "../core/form_context";
import {FaArrowCircleDown, FaArrowCircleUp, FaDownload, FaInfoCircle} from "react-icons/fa";
import {IoMdCloseCircle} from "react-icons/io";
import {getUsers} from "../core/core";
import Popup from "reactjs-popup";
import {UserContext} from "../user/UserContext";
import {downloadAsExcel} from "../core/download";

class FilingsList extends React.Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.config = new ViewConfig();

        this.stat = props.match.params.status;
        if (props.location.pathname === "/companies") {
            this.stat = "companies";
        }
        this.state = {}
        if (this.stat !== undefined && this.stat !== "" && this.stat !== "submitted" && this.stat !== "unsubmitted" && this.stat !== "open" && this.stat !== "companies") {
            return;
        }
        //console.log(this.props.location.state)
        this.state = {
            status: props.match.params.status,
            filingExpanded: props.match.params.id === undefined ? 0 : parseInt(props.match.params.id, 0),
            filings: {
                submitted: [],
                unsubmitted: [],
            },
            select: {},
            loginStatus: null,
            error: "",
            loading: true,
            search: {
                companyName: this.config.get("companyName") || "",
                country: this.config.get("country") || "all",
                report: this.config.get("report") || "all",
                accountManagers: this.config.get("accountManagers") || undefined,
                managerRole: this.config.get("managerRole") || "both",
                reviewer: this.config.get("reviewer") || false,
                period: this.config.get("periodview") || "all",
                periodComp: this.config.get("periodCompview") || "all",
                dueDateLeft: this.config.get("dueDateLeft"),
                dueDateRight: this.config.get("dueDateRight"),
                dateType: this.config.get("dateType"),
            },
            sortUnsubmitted: {
                attribute: this.config.get("sortUnsubmitted") || "Due",
                asc: this.config.get("sortUnsubmittedAsc", true),
                env: "Unsubmitted",
            },
            sortSubmitted: {
                attribute: this.config.get("sortSubmitted") || "Submitted",
                asc: this.config.get("sortSubmittedAsc", false),
                env: "Submitted",
            }
        };
        this.searchFields = ["companyName", "country", "report", "period", "periodComp", "dueDateLeft", "dueDateRight"];

        this.apiGet = apiGet.bind(this);
        this.setState = this.setState.bind(this);
        this.setExpanded = this.setExpanded.bind(this);

        //this.getState = this.getState.bind(this);
        this.cb = this.cb.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateState = this.updateState.bind(this);
        this.componentDidUpdate = this.componentDidUpdate.bind(this);
        this.componentDidMount = this.componentDidMount.bind(this);
        this.searchByName = this.searchByName.bind(this);

        this.searchByName("fromconstructor")

    }

    componentWillReceiveProps(nextProps) {
        this.setState({propsUpdated: true})
    }

    componentDidMount() {
        const selects = document.getElementsByTagName("select");
        for (var i = 0; i < selects.length; i++) {
            const name = selects[i].getAttribute("name").split("_");
            this.updateState(name[0], name[1], selects[i].value)
        }
        getUsers(this.apiGet, this.setState)
    }

    componentDidUpdate() {

        if (this.state === undefined || this.state.search === undefined || this.state.search === null) {
            return
        }
        this.config.save({
            companyName: this.state.search.companyName,
            country: this.state.search.country,
            report: this.state.search.report,
            accountManagers: this.state.search.accountManagers,
            managerRole: this.state.search.managerRole,
            reviewer: this.state.search.reviewer,
            periodview: this.state.search.period,
            periodCompview: this.state.search.periodComp,
            dateType: this.state.search.dateType,
            dueDateLeft: this.state.search.dueDateLeft,
            dueDateRight: this.state.search.dueDateRight,
            sortUnsubmitted: this.state.sortUnsubmitted.attribute,
            sortUnsubmittedAsc: this.state.sortUnsubmitted.asc,
            sortSubmitted: this.state.sortSubmitted.attribute,
            sortSubmittedAsc: this.state.sortSubmitted.asc,
        })
    }

    /*getState(start = 0) {
        console.log("lsjdlfj")
        return
        var status;
        switch (this.stat) {
            case undefined:
                status = -1;
                break;
            case "submitted":
                status = 2;
                break;
            case "unsubmitted":
                status = 0;
                break;
            default:
                status = 1;
                break;
        }


    }*/

    cb() {
        if (!this.state.filingExpanded > 0)
            return;
        let id = "f" + this.state.filingExpanded;
        let a = document.getElementById(id);
        if (a === null) {
            return
        }
        let b = a.parentElement.getElementsByTagName("tr");
        for (var i = 0; i < b.length; i++) {
            if (b[i].getAttribute("id") === id) {
                break
            }
        }
        if (i > 1) {
            b[i - 2].scrollIntoView(true)
            // /console.log(b[ i - 2],i)
        } else {
            a.parentElement.parentElement.scrollIntoView()
            //console.log(a.parentElement.parentElement,i)
        }


    }

    updateState(parent, child, value, callback = () => {
    }) {
        console.log(parent, child, value)
        if (child === "") {
            this.setState({parent: value}, callback);
        } else {
            if (parent === "search") {
                console.log({value})
                callback = () => this.searchByName(this.state.search.field)
            }
            this.setState((prevState, props) => ({
                [parent]: {
                    ...prevState[parent],
                    [child]: value,
                }
            }), callback);
        }

    }

    handleChange(e) {
        const s = e.target.name.split("_");
        if (s.length === 2) {
            this.updateState(s[0], s[1], e.target.value);
        } else {
            this.updateState(s[0], "", e.target.value)
        }


    }

    setExpanded = (id) => {
        this.setState({
            filingExpanded: id
        })
    };

    checkLogin = () => {
        this.apiGet("/user/status", resp => {
            this.setState({
                loginStatus: resp,
            })
        });

    };

    searchByName(name = "", limitless = false, statusOverwrite = null, download = false) {
        const originalName = name;
        if (name !== "fromconstructor") {
            this.setState({loading: true})
        } else {
            name = "";
        }

        let status;
        switch (this.props.match.params.status) {
            case undefined:
                status = -1;
                break;
            case "submitted":
                status = 2;
                break;
            case "unsubmitted":
                status = 0;
                break;
            default:
                status = 1;
                break;
        }
        if (status === 1 && this.state.search.periodComp === "") {
            status = 2;
        }
        status = statusOverwrite ?? status;

        if (this.stat === "companies") {
            this.apiGet("/companies/list", resp => {
                this.setState({
                    companies: resp.results,
                    loading: false,
                    error: ""
                })
            });
        } else {
            let timeout = 0;
            if ((originalName === "fromconstructor" && this.props.location.state !== undefined && this.props.location.state.updating && !download) || this.state.loading) {
                timeout = 200;
            }

            console.log("with timeout", timeout);//, timeout, originalName == "fromconstructor", this.props.location.state !== undefined, this.props.location.state.updating)
            if (this.state.timer) {
                clearTimeout(this.state.timer)
            }
            const timer = setTimeout(() => {
                const searchStateString = JSON.stringify(this.state.search);
                const name = this.state.search.companyName
                this.apiGet(`/vat_return/get_list?` + encodeGetParams({
                    name,
                    limitless,
                    status,
                    ...this.state.search,
                    download,
                    //accountManagers: this.state.search?.accountManagers?.map(({value}) => value).join(",") || ""
                }), resp => {
                    if (JSON.stringify(this.state.search) !== searchStateString) {
                        return;
                    }

                    if (download) {
                        downloadAsExcel(resp.content, "Export.xlsx")
                        this.setState({loading: false})
                    } else {
                        if (name !== this.state.search.companyName) {
                            return
                        }
                        this.setState({
                            filings: limitless ?
                                (statusOverwrite ?
                                    {
                                        ...this.state.filings,
                                        [statusOverwrite === 0 ? "unsubmitted" : "submitted"]: resp.results[[statusOverwrite === 0 ? "unsubmitted" : "submitted"]],
                                    }
                                    : {
                                        submitted: resp.results.submitted || this.state.filings.submitted,
                                        unsubmitted: resp.results.unsubmitted || this.state.filings.unsubmitted,
                                    }) :
                                resp.results
                            ,
                            periods: resp.periods,
                            end: resp.end,
                            loading: false
                        }, this.cb);

                        if (this.state.search.period !== undefined && this.state.search.period !== "all" && resp.periods.indexOf(this.state.search.period) === -1) {
                            this.updateState("search", "period", "all", this.searchByName)

                        }
                    }

                });
            }, timeout);
            this.setState({timer})
        }

    }

    toggleSort = (attribute, sort) => {
        //console.log(attribute, sort)
        this.setState({["sort" + sort.env]: {attribute: attribute, asc: attribute !== sort.attribute ? true : !sort.asc, env: sort.env}})
    };
    sortCompanies = (a, b, sort) => {
        const accessor = this.attributeMaps[sort.attribute.replace(" ", "")];
        //console.log(a, b, accessor(a), accessor(b), accessor(a) > accessor(b))
        if (!accessor) {
            return 1
        }
        let val = accessor(a) > accessor(b);

        if (accessor(a) === accessor(b)) {
            val = a.ID > b.ID;
        }
        return (sort.asc ? val : !val) ? 1 : -1
    };
    attributeMaps = {
        CompanyName: c => c.data && c.data.company ? c.data.company.name || "z" : "z",
        Type: c => c.data && c.data.company ? c.data.general.country + c.data.general.report || "z" : "z",
        Period: c => c.data && c.data.specific ? (c.data.specific.year || 0) + "_" + ((c.data.specific.period?.length < 3 ? "0" : "") + c.data.specific.period) || "z" : "z",
        Submitted: c => c.data.specific !== undefined && c.data.specific.submitTime !== undefined ? c.data.specific.submitTime : c.submit_time,
        LastSaved: c => c.data.lastSaved,
        Due: c => c.duedateDays,
        DueInternally: c => c.iduedateDays,
        Review: c => {
            const review = c.data.review || {};
            const myID = this.context?.user?.ID;
            /*
            0 not existent
            1 approved
            2 not started but users existent
            3 started but not me
            4 started and me is reviewer
             */
            return ((review.users?.length || 0) === 0 || (review.users[0]?.length || 0) === 0) ? 0 : (
                review.approved ? 1 : (
                    !review.started ? 2 : (
                        review.users.some(a => a?.some(b => b === myID)) ? 4 : 3
                    )
                )
            )
        },
    };

    render() {
        const status = this.props.match.params.status;
        if (status !== undefined && status !== "" && status !== "submitted" && status !== "unsubmitted" && status !== "open" && status !== "companies") {
            return null;
        }
        const filings = this.state.filings;
        const unsubmittedUnsorted = filings.unsubmitted; // (d.data.general.report.indexOf("manual") === -1 || (d.data.specific === undefined || !d.data.specific.submitted)) &&
        const submittedUnsorted = filings.submitted; //  (d.data.general.report.indexOf("manual") > -1 && (d.data.specific !== undefined && d.data.specific.submitted)) ||

        const unsubmitted = unsubmittedUnsorted.sort((a, b) => -a.data.lastSaved + b.data.lastSaved);
        const submitted = submittedUnsorted.sort((a, b) => {
            //const timeA = a.submit_time === 0 ? a.data.lastSaved : a.submit_time;
            //const timeB = b.submit_time === 0 ? b.data.lastSaved : b.submit_time;
            const timeA = a.submit_time === 0 ? ((((a.data || {}).specific || {}).submitTime || 0) !== 0 ? a.data.specific.submitTime : a.data.lastSaved) : a.submit_time;
            const timeB = b.submit_time === 0 ? ((((b.data || {}).specific || {}).submitTime || 0) !== 0 ? b.data.specific.submitTime : b.data.lastSaved) : b.submit_time;

            return -timeA + timeB
        });
        if (this.state.propsUpdated && this.props.match.params.status !== this.stat) {
            // resolving redirect from overview to (un)submitted as filings are not reloaded otherwise
            window.setTimeout(() => {
                this.setState({propsUpdated: false})
                window.setTimeout(() => {
                    //console.log("jsldjfl",this.state.loading,this.props.location)
                    this.searchByName()
                }, 100)
            }, 100)
        }

        const showLimit = 40;
        const anyInternalDuedate = unsubmitted.some(a => !!a.iduedate && a.duedate !== a.iduedate);

        return <FormContext.Provider value={{
            state: this.state, setState: this.setState, updateState: this.updateState,
        }}>
            {/*<button onClick={this.checkLogin}>status</button>*/}
            <div key={"changer"}>
                <div style={{
                    float: "right",
                    color: 'white',
                    display: "inline-block",
                    margin: 0,
                    textAlign: "right",
                    lineHeight: "80px"
                }}>
                    {
                        status !== undefined &&
                        <div style={{marginTop: "100px"}}/>
                    }
                    <InsertIfNonClient>
                        <Link to="/filings/new" className={'btn'} style={{color: 'white'}}>
                            <i style={{color: "white"}} className={"fa fa-plus"}/>&nbsp;&nbsp;&nbsp;&nbsp;
                            add new
                        </Link>
                    </InsertIfNonClient>
                    {/*<br/>
                        <Link to="/filings/quickadd" className={'btn'} style={{color: 'white'}}>
                            <i style={{color: "white"}} className={"fa fa-plus"}></i>&nbsp;&nbsp;
                            quick add
                        </Link>*/}
                </div>

                {/*
                    <div>
                        {unsubmitted.length >= 20 && status=== undefined && <Link to="/filings/status/unsubmitted">Unsubmitted Filings</Link>}
                        {unsubmitted.length < 20 && status=== undefined && <a href="#unsubmitted_filings">Unsubmitted Filings</a>}
                        &nbsp;|&nbsp;
                        {submitted.length >= 20 && status=== undefined && <Link to="/filings/status/submitted">Submitted Filings</Link>}
                        {submitted.length < 20 && status=== undefined && <a href="#submitted_filings">Submitted Filings</a>}




                    </div>
                    */}
                {
                    status === undefined &&
                    <FilingsSelector state={this.state} updateState={this.updateState} handlechange={this.handleChange}/>
                }

                <Status type={"error"} text={this.state.error}/>
            </div>
            {
                (status === undefined || status === "unsubmitted") && <div id={"filings_list"} key={"unsubmitted"}>
                    {
                        Consts.PHASE === "development2" && <div style={{float: "right", width: "300px", marginTop: "200px"}}>
                            <JSONPretty id="json-pretty" json={this.state}/>
                        </div>
                    }
                    <h1 id={"unsubmitted_filings"} style={{marginTop: "-100px", paddingTop: "100px"}}>
                        Unsubmitted Filings {/*this.state.search.period !== "" && <span>- <Link to={"/filings/status/open"}>show open filings for this period</Link></span>*/}
                        <FaDownload style={{cursor: "pointer"}} onClick={() => this.searchByName("", 0, 0, true)}/>
                        &nbsp;
                        <Loader loading={this.state.loading}/>
                    </h1>

                    {
                        status !== undefined && <FilingsSelector state={this.state} handlechange={this.handleChange}/>
                    }


                    <table style={{width: "100%"}}>
                        <tr>
                            <SortOrderChanger change={this.toggleSort} sort={this.state.sortUnsubmitted} style={{paddingLeft: "35px"}}>Company Name</SortOrderChanger>
                            <SortOrderChanger change={this.toggleSort} sort={this.state.sortUnsubmitted}>Type</SortOrderChanger>
                            <SortOrderChanger change={this.toggleSort} sort={this.state.sortUnsubmitted}>Period</SortOrderChanger>
                            <SortOrderChanger change={this.toggleSort} sort={this.state.sortUnsubmitted}>Due</SortOrderChanger>
                            {
                                anyInternalDuedate && <SortOrderChanger change={this.toggleSort} sort={this.state.sortUnsubmitted}>Due Internally</SortOrderChanger>
                            }
                            <SortOrderChanger change={this.toggleSort} sort={this.state.sortUnsubmitted}>Data Uploaded</SortOrderChanger>
                        </tr>
                        {
                            unsubmitted.length > 0 && unsubmitted.sort((a, b) => this.sortCompanies(a, b, this.state.sortUnsubmitted)).map((f) =>
                                <ListUnsubmittedItems filing={f} key={f.ID + "uns"} t={this}{...{anyInternalDuedate}}/>
                            )
                        }
                    </table>
                    {
                        unsubmitted.length === 0 && "There is none yet."
                    }
                    {
                        filings.unsubmitted.length >= showLimit && !this.state.end && <>
                            <em onClick={() => this.searchByName("", true, 0)}>show all</em>
                            <Loader loading={this.state.loading}/>
                        </>
                    }
                    <br/>
                </div>}
            {(status === undefined || status === "submitted") &&
                <div id={"filings_list"} key={"submitted"}>

                    <h1 id={"submitted_filings"} style={{marginTop: "-100px", paddingTop: "100px"}}>
                        Submitted Filings&nbsp;
                        <FaDownload style={{cursor: "pointer"}} onClick={() => this.searchByName("", 0, 2, true)}/>
                    </h1>

                    {status !== undefined &&
                        <FilingsSelector state={this.state} handlechange={this.handleChange}/>
                    }

                    <table style={{width: "100%"}}>
                        <tr>
                            <SortOrderChanger change={this.toggleSort} sort={this.state.sortSubmitted} style={{paddingLeft: "35px"}}>Company Name</SortOrderChanger>
                            <SortOrderChanger change={this.toggleSort} sort={this.state.sortSubmitted}>Type</SortOrderChanger>
                            <SortOrderChanger change={this.toggleSort} sort={this.state.sortSubmitted}>Period</SortOrderChanger>
                            <SortOrderChanger change={this.toggleSort} sort={this.state.sortSubmitted}>Submitted</SortOrderChanger>
                        </tr>
                        {submitted.length > 0 &&
                            submitted.sort((a, b) => this.sortCompanies(a, b, this.state.sortSubmitted)).map((f) =>
                                <ListSubmittedItems filing={f} key={f.ID} t={this}/>
                            )
                        }
                    </table>
                    {submitted.length === 0 &&
                        "There is none yet."
                    }

                    {/*filings.submitted.length >= showLimit && status === undefined &&
                <Link to={{pathname: "/filings/status/submitted", reloadData: true}}>show more</Link>*/
                    }
                    {
                        filings.submitted.length >= showLimit && !this.state.end && <>
                            <em onClick={() => this.searchByName("", true, 2)}>show all</em>
                            <Loader loading={this.state.loading}/>
                        </>
                    }
                </div>
            }
            {
                status !== undefined && status === "open" &&
                <div id={"filings_list"} key={"open"}>

                    <h1 id={"open_companies"} style={{marginTop: "-100px", paddingTop: "100px"}}>Open Filings</h1>

                    <Loader loading={this.state.loading} div/>

                    {status !== undefined &&
                        <FilingsSelector state={this.state} status={"open"} handlechange={this.handleChange}/>
                    }
                    <table style={{width: "100%"}}>

                        {submitted.length > 0 &&
                            submitted.map((f) =>
                                <ListOpenItems filing={f} key={f.id} t={this}/>
                            )
                        }
                        {submitted.length === 0 &&
                            "There is none yet."
                        }

                        {/*filings.submitted.length >= showLimit && status === undefined &&
                        <Link to={{pathname: "/filings/status/submitted", reloadData: true}}>show more</Link>*/
                        }
                        {
                            filings.submitted.length >= showLimit && status !== undefined && !this.state.end && <em onClick={() => {
                                this.searchByName("", true)
                            }}>show all </em>
                        }

                    </table>
                </div>
            }
            {
                status !== undefined && status === "companies" &&
                <div id={"filings_list"} key={"companies"}>

                    <h1 id={""} style={{marginTop: "-100px", paddingTop: "100px"}}>Legal Entities</h1>

                    <Loader loading={this.state.loading} div/>

                    {status !== undefined &&
                        <FilingsSelector state={this.state} status={"open"} handlechange={this.handleChange}/>
                    }
                    <table style={{width: "100%"}}>

                        {submitted.length > 0 &&
                            submitted.map((f) =>
                                <ListOpenItems filing={f} key={f.id} t={this}/>
                            )
                        }
                        {submitted.length === 0 &&
                            "There is none yet."
                        }

                        {filings.submitted.length >= showLimit && status === undefined &&
                            <Link to={{pathname: "/filings/status/submitted", reloadData: true}}>show more</Link>
                        }
                        {filings.submitted.length >= showLimit && status !== undefined && !this.state.end &&
                            <em onClick={() => {
                                this.searchByName("", true)
                            }}>show all</em>
                        }

                    </table>
                </div>
            }
        </FormContext.Provider>
    }
}

const SortOrderChanger = ({change, children, sort, style}) => {
    return <th style={style}>
        <em onClick={() => change(children, sort)}>
            {children}
            &nbsp;
            &nbsp;
            {sort.attribute === children ?
                !sort.asc ? <FaArrowCircleUp style={{fontSize: "15px"}}/> : <FaArrowCircleDown style={{fontSize: "15px"}}/> :
                null
            }
        </em>
    </th>;
}

function FilingsSelector(props) {
    const context = useContext(UserContext);
    //type={"reactselect-simple"} width={"200px"}
    const {search, selectUsers} = props.state;
    return <form onChange={props.handlechange}>
        <TextfieldInput tag={"search_companyName"} name={"Search by Name"} ph={"company 1"} state={props.state}/> &nbsp;
        <IoMdCloseCircle style={{marginBottom: "-4px", cursor: "pointer"}} title={"remove from list"} onClick={() => {
            props.handlechange({target: {name: "search_companyName", value: "", type: "button"}})
        }}/>
        <br/>
        <SelectField name="Choose Country " tag="search_country" selectives={(props.status === "open" ? [] : [["all", "all"]]).concat(Consts.reportCountriesSelect)} state={props.state} noBreaks/> &nbsp;

        {

            <SelectField name="Type" tag="search_report" noBreaks
                         selectives={[
                             ...(props.status === "open" ? [] : [["all", "all"]]),
                             ...(props.state.search.country !== "all" ?
                                 (Consts.reports[props.state.search.country]?.map(a => [a.toLowerCase().split(" ").join("_"), a]) ?? []) :
                                 (Array.from(new Set(Object.values(Consts.reports).reduce((obj, curr) => [...obj, ...curr], []))).map(a => [a.toLowerCase().split(" ").join("_"), a])).sort((a, b) => a[1].replace("Manual ","") < b[1].replace("Manual ","") ? -1 : 1))
                         ]
                         }
                         state={props.state}/>
        }


        {
            props.state.search.report !== "all" && props.state.periods?.length > 0 &&
            <SelectField name="Period" tag="search_period" noBreaks selectives={[...(props.status === "open" ? [] : [["all", "all"]]), ...formatDateToSelect(props.state.periods, props)]}
                         state={props.state}/>

        }
        {
            props.state.search.country !== "all" && props.state.search.report !== "all" && props.state.periods?.length === 0 && props.state.search.period !== "all" &&
            <SelectField name="Period" tag="search_period" noBreaks selectives={formatDateToSelect([props.state.search.period], props)} state={props.state}/>
        }


        {
            props.state.search.country !== "all" && props.state.search.report !== "all" && props.state.periods?.length > 0 && props.state.search.period !== undefined && props.status === "open" &&
            <span> in comparision to&nbsp;
                <SelectField name="Period" tag="search_periodComp" noBreaks selectives={formatDateToSelect(props.state.periods, props, props.state.search.period)} state={props.state}/>
        </span>
        }
        {
            props.state.search.country !== "all" && props.state.search.report !== "all" && props.state.periods?.length === 0 && props.state.search.periodComp !== "all" && props.status === "open" &&
            <span> in comparision to&nbsp;
                <SelectField name="Period" tag="search_periodComp" noBreaks selectives={formatDateToSelect([props.state.search.periodComp], props)} state={props.state}/>
            </span>
        }
        <br/>
        <DateInput name={<><em onClick={(e) => {
            e.preventDefault()
            props.updateState("search", "dateType", {"Submission Date": "Due Date"}[props.state?.search?.dateType] || "Submission Date")
        }}>{props.state?.search?.dateType || "Due Date"}</em> from</>} updateState={props.updateState} tag={"search_dueDateLeft"}/>
        <IoMdCloseCircle style={{marginBottom: "-2px", cursor: "pointer"}} title={"remove from list"} onClick={() => {
            props.handlechange({target: {name: "search_dueDateLeft", value: "", type: "button"}})
        }}/>&nbsp;
        <DateInput name={"till"} updateState={props.updateState} tag={"search_dueDateRight"}/>
        <IoMdCloseCircle style={{marginBottom: "-4px", cursor: "pointer"}} title={"remove from list"} onClick={() => {
            props.handlechange({target: {name: "search_dueDateRight", value: "", type: "button"}})
        }}/>
        <br/>
        {
            selectUsers && props.updateState && context.user.Role >= 40 && <div style={{display: "inline-block", marginRight: 20}}>
                <b style={{cursor: "pointer"}}>Manager </b>

                <SelectfieldInput type={"reactselect"} inline noLabel tag={"search_accountManagers"} name={"Account Manager"} width={Math.min(window.innerWidth - 50, 300)} selectives={[
                    {value: "unassigned", label: "unassigned"},
                    ...selectUsers
                        .sort((a, b) => a.name < b.name ? -1 : 1)
                        .filter(u => u.role > 30)
                        .map(u => ({value: u?.ID?.toString() /* json search needs string*/, label: u.name + " (" + u.email + ")"}))
                ]}/>
                &nbsp;
                &nbsp;
                <b>Manager Role</b>
                <SelectfieldInput type={"reactselect"} inline noLabel tag={"search_managerRole"} selectives={[{label: "both", value: "both"}, {value: "primary", label: "Account Managers"}, {value: "backup", label: "Backup Account Managers"}]}/>
            </div>
        }
        {
            /*selectUsers && props.updateState && <div style={{display: "inline-block"}}>
                <b>To be Reviewed by</b>
                <SelectfieldInput type={"reactselect"} inline noLabel tag={"search_reviewer"} name={"Reviewer"} width={Math.min(window.innerWidth - 50, 300)} selectives={[
                    {value: context.user.ID?.toString(), label: "me"},
                    ...(context.user.Role >= 40 ? selectUsers
                        .sort((a, b) => a.name < b.name ? -1 : 1)
                        .filter(u => u.role > 30)
                        .map(u => ({value: u?.ID?.toString() /* json search needs string, label: u.name + " (" + u.email + ")"})) : [])
                ]}/>
            </div>*/
        }


    </form>
}

function formatDateToSelect(input, props, leaveOut = "") {
    return input.map(a => [a, a.split("|")])
        .sort((a, b) => {
            if (a[1][0] !== b[1][0]) {
                return a[1][0] < b[1][0] ? 1 : -1
            }
            if (!isNaN(parseInt(a[1][1]))) {
                return parseInt(a[1][1]) < parseInt(b[1][1]) ? 1 : -1
            }
            return a[1][1] < b[1][1] ? 1 : -1;
        })
        .filter(a => a[0] !== leaveOut)
        .map(([a, b]) => {
            if (props.state.search.country === "GB" && props.state.search.report === "vat_return") {
                return [a, b[1] > 90 || b[1] < 15 ? (b[0] + " - " + DEPeriods[b[1]]) : (GBPeriods[(b[1] || "").substr(2)] !== undefined ? GBPeriods_date(b[1]) : b[1])]
            }
            const year = b[0];
            const period = b[1];
            if (DEPeriods[period] !== undefined) {
                return [a, year + " - " + (DEPeriods[period] || period)]
            }
            return [a, b[0] + " - " + b[1]]
        })
}


/*

}
*/


export default FilingsList;
